import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthService } from "./../services/auth.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.authService.userValue;
    const lastActivity = this.authService.lastAccess;
    const isTokenValid =
      new Date().getTime() - lastActivity < environment.tokenValidTime;
    const isLoggedIn = user && user.token && isTokenValid;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const userLanguage = this.authService.appUserLang || "es";
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.token}`,
          "Accept-Language": userLanguage,
        },
      });
    }
    this.authService.lastAccess = new Date().getTime();
    return next.handle(request);
  }
}
