export const environment = {
  production: true,
  apiUrl: "https://api.staging.cultuar.beebitpruebas.com/api/v1",
  //apiUrl: "https://api.test.cultuar.beebitpruebas.com/api/v1",
  tokenValidTime: 7200000,
  geoapifyKey: "a65bc3c3a695421badfb39c576c675c0",
  openStreetMapKey:
    "pk.eyJ1IjoiYm9yamFtYXpvIiwiYSI6ImNrd2Npb2loNTBhaTUyb3F2bHRuN2dlc24ifQ.vkzmY4oju86JfSDRVQGo_A",
  mapBoxTokenOld:
    "pk.eyJ1IjoiaXRhcnZpc2lvbiIsImEiOiJjbDBjOHpvbjQwNTZpM2NwMjFyc2ZzcTB1In0.kgjrAKe8uqconHUfhYo09w",
  mapBoxToken:
    "pk.eyJ1IjoiaXRhcnZpc2lvbiIsImEiOiJjbDNsOWd1bmgwa3N4M2NwdnVpMG5tb2tlIn0.fWH3exEHDk4hOIMv8Fbt4A",
  hj: "3144288",
};
