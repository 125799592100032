import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { first, map } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { environment } from "@environments/environment";
import { User } from "@core/models/user";
import { Organization } from "../models/organization";
import { Store } from "@ngrx/store";
import { AppState } from "@app/store/models/app-state.model";
import { SetOrganization } from "@app/store/actions/app.actions";

@Injectable({ providedIn: "root" })
export class OrganizationsService {
  selectedOrganization: any;
  userData: any;
  isSuperAdmin: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.store.subscribe((state: any) => {
      this.isSuperAdmin = state.AppState.isSuperAdmin;
      this.selectedOrganization =
        state.AppState.organization && state.AppState.organization.id;
    });
  }

  // tslint:disable-next-line:typedef
  getOrganizations() {
    return this.http
      .get<User>(`${environment.apiUrl}/accounts`, {})
      .pipe(map((data) => data));
  }

  getOrganizationInfo(id: string) {
    if (this.isSuperAdmin) {
      return this.http
        .get<User>(`${environment.apiUrl}/accounts/${id}`, {})
        .pipe(map((data) => data));
    } else {
      return this.http
        .get<User>(`${environment.apiUrl}/accounts/current`)
        .pipe(map((data) => data));
    }
  }

  addUpdateOrganization(data: any) {
    if (data.id) {
      return this.http
        .patch(`${environment.apiUrl}/accounts/` + data.id, data)
        .pipe(map((data) => data));
    } else {
      delete data.id;
      delete data.tags;
      return this.http
        .post(`${environment.apiUrl}/accounts/`, data)
        .pipe(map((data) => data));
    }
  }

  deleteOrganization(id) {
    return this.http
      .delete<User>(`${environment.apiUrl}/accounts/` + id)
      .pipe(map((data) => data));
  }

  setOrganization(id: any, data?: any) {
    this.selectedOrganization = id;
    this.userData = this.authService.userValue;
    if (id && data) {
      const localStorageToken = JSON.stringify({
        user: this.userData,
        lastAccess: new Date().getTime(),
        isSuperAdmin: this.isSuperAdmin,
        organization: data,
      });
      this.store.dispatch(new SetOrganization(data));
      this.authService.organizationObs = data;
      localStorage.setItem("cultuar", localStorageToken);
    } else if (id) {
      this.getOrganizationInfo(id)
        .pipe(first())
        .subscribe((data: any) => {
          const localStorageToken = JSON.stringify({
            user: this.userData,
            lastAccess: new Date().getTime(),
            isSuperAdmin: this.isSuperAdmin,
            organization: data,
          });
          this.store.dispatch(new SetOrganization(data));
          this.authService.organizationObs = data;
          localStorage.setItem("cultuar", localStorageToken);
        });
    } else {
      const localStorageToken = JSON.stringify({
        user: this.userData,
        lastAccess: new Date().getTime(),
        isSuperAdmin: this.isSuperAdmin,
        organization: null,
      });
      localStorage.setItem("cultuar", localStorageToken);
      this.store.dispatch(new SetOrganization(null));
    }
    this.authService.lastAccess = new Date().getTime();
  }

  getSelectedOrganization() {
    return this.selectedOrganization;
  }

  activateDeactivateOrganization(id, active) {
    if (active) {
      return this.http
        .patch<User>(`${environment.apiUrl}/accounts/` + id + "/activate", "")
        .pipe(map((data) => data));
    } else {
      return this.http
        .patch<User>(`${environment.apiUrl}/accounts/` + id + "/deactivate", "")
        .pipe(map((data) => data));
    }
  }

  organizationsDownload() {
    const headers = new HttpHeaders();
    headers.append("Accept", "text/xlsx");
    headers.append("Content-Type", "text/xlsx");

    let filename = "cultuar.xlsx";

    return this.http
      .get(`${environment.apiUrl}/export-excel`, {
        headers: headers,
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (filename) downloadLink.setAttribute("download", filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        })
      );
  }

  templateDownload() {
    const headers = new HttpHeaders();
    headers.append("Accept", "text/xlsx");
    headers.append("Content-Type", "text/xlsx");

    let filename = "cultuar.xlsx";

    return this.http
      .get(`${environment.apiUrl}/export-excel?template`, {
        headers: headers,
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: any) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (filename) downloadLink.setAttribute("download", filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        })
      );
  }

  importOrganizations(file) {
    var formdata = new FormData();
    formdata.append("data", file);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.http
      .post<any>(`${environment.apiUrl}/import-excel`, formdata, {
        headers: headers,
      })
      .pipe(
        map((data) => {
          console.log(data);
        })
      );
  }
}
