import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { environment } from "@environments/environment";
import { User } from "@core/models/user";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class RoutesService {
  selectedRoute: any;
  userData: any;
  constructor(private http: HttpClient, private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  getRoutes(organizationId) {
    this.userData = this.authService.userValue;

    let endPoint = organizationId
      ? `${environment.apiUrl}/accounts/${organizationId}/stages`
      : `${environment.apiUrl}/stages`;

    return this.http.get<User>(endPoint, {}).pipe(map((data) => data));
  }

  setSelectedRoute(route: any) {
    this.selectedRoute = route;
  }

  getSelectedRoute() {
    return this.selectedRoute;
  }

  getRouteInfo(routeId) {
    this.userData = this.authService.userValue;
    return this.http
      .get<User>(`${environment.apiUrl}/stages/` + routeId, {})
      .pipe(
        map((data: any) => {
          if (data.description && data.description.es) return data;
          return { ...data, description: { es: "" } };
        })
      );
  }

  deleteRoute(routeId) {
    return this.http
      .delete<any>(`${environment.apiUrl}/stages/` + routeId, {})
      .pipe(map((data) => data));
  }

  addUpdateRoute(data: any) {
    if (data.id != "") {
      return this.http
        .patch<User>(`${environment.apiUrl}/stages/` + data.id, data)
        .pipe(map((data) => data));
    } else {
      if (data.accountId) {
        return this.http
          .post<User>(
            `${environment.apiUrl}/accounts/${data.accountId}/stages`,
            data
          )
          .pipe(map((data) => data));
      } else {
        console.error("A organization must be selected");
        return new Observable((observer) => observer.next(false));
      }
    }
  }

  getRoutesDificulties() {
    return this.http
      .get<User>(`${environment.apiUrl}/stage-difficulty-types`, {})
      .pipe(map((data: any) => data));
  }
}
