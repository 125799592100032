import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class LanguageService {
  constructor(private http: HttpClient) {}

  downloadLanguage(){
    return this.http
      .get(`${environment.apiUrl}/language`, {})
      .pipe(map((data) => data));
  }

  importLanguage(file) {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("category", "json");

    return this.http
      .post<any>(`${environment.apiUrl}/language`, file).pipe(map((data) => data));
  }

}
