import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { environment } from "@environments/environment";
import { User } from "@core/models/user";

@Injectable({ providedIn: "root" })
export class UsersService {
  selectedUser: any;
  userData: any;
  constructor(private http: HttpClient, private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  getUsers(accountId?) {
    this.userData = this.authService.userValue;

    if (accountId) {
      return this.http
        .get<User>(`${environment.apiUrl}/accounts/${accountId}/users`, {})
        .pipe(map((data) => data));
    } else {
      return this.http
        .get<User>(`${environment.apiUrl}/users`, {})
        .pipe(map((data) => data));
    }
  }

  setSelectedUser(route: any) {
    this.selectedUser = route;
  }

  getSelectedUser() {
    return this.selectedUser;
  }

  addUpdateUser(data: any) {
    if (data.id) {
      return this.http
        .patch(`${environment.apiUrl}/users/` + data.id, data)
        .pipe(map((data) => data));
    } else {
      return this.http
        .post(`${environment.apiUrl}/users/`, data)
        .pipe(map((data) => data));
    }
  }

  deleteUser(id) {
    return this.http
      .delete(`${environment.apiUrl}/users/` + id)
      .pipe(map((data) => data));
  }

  updateUserPolicy(userId, data) {
    return this.http
      .patch(`${environment.apiUrl}/users/` + userId, data)
      .pipe(map((data) => data));
  }
}
