import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "cultuar-admin-panel";

  constructor(public translate: TranslateService) {
    translate.addLangs(["en", "es", "it"]);

    const language =
      JSON.parse(localStorage.getItem("cultuar"))?.appUiLanguage || "es";

    translate.setDefaultLang(language);
    console.log("version 1.0 - Test Enviroment - 21/11/2022");
    console.log("improvements");
    console.log("CLTR-2694");
    console.log("CLTR-2697");
    console.log("dropdown sorted");
  }
}
