import { Injectable } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationHistoryService {
  public backButton: Subject<boolean> = new Subject();
  private history: any[] = [];

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // tslint:disable-next-line:no-unused-expression
        if (event.url.split(";")[0] !== this.history[this.history.length - 1]) {
          this.addToHistory(event.url.split(";")[0]);
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.error(event.error);
      }
    });
  }

  // tslint:disable-next-line:typedef
  addToHistory(url) {
    if (url !== "/login") {
      // tslint:disable-next-line:no-unused-expression
      this.history.push(url);
      // tslint:disable-next-line:no-unused-expression
      this.history.length > 1 && this.backButton.next(true);
    }
  }

  // tslint:disable-next-line:typedef
  goBack() {
    if (this.history.length > 1) {
      this.history = this.history.slice(0, this.history.length - 1);
      if (this.history.length === 1) {
        this.backButton.next(false);
      }
      return this.history[this.history.length - 1];
    }
    if (this.history.length === 1) {
      this.backButton.next(false);
    }
    return false;
  }

  // tslint:disable-next-line:typedef
  isVisible() {
    return this.history.length > 0;
  }

  // tslint:disable-next-line:typedef
  resetHistory() {
    this.backButton.next(false);
    this.history = [];
  }
}
