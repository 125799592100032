// import the interface
import { AppActionType } from "../actions/app.actions";
import { AppState } from "@app/store/models/app-state.model";
//create a dummy initial state

const initialState: AppState = {
  user: localStorage.getItem("cultuar")
    ? JSON.parse(localStorage.getItem("cultuar")).user
    : null,
  isSuperAdmin: localStorage.getItem("cultuar")
    ? JSON.parse(localStorage.getItem("cultuar")).isSuperAdmin
    : false,
  organization: localStorage.getItem("cultuar")
    ? JSON.parse(localStorage.getItem("cultuar")).organization
    : "",
  logged: false,
  point: "",
  route: "",
  active: true,
  appUiLanguage: localStorage.getItem("cultuar")
    ? JSON.parse(localStorage.getItem("cultuar")).appUiLanguage
    : "es",
};

export const AppReducer = (state: AppState = initialState, action) => {
  switch (action.type) {
    case AppActionType.SET_USER_INFO:
      return { ...state, user: action.payload };
    case AppActionType.SET_ORGANIZATION:
      return { ...state, organization: action.payload };
    case AppActionType.SET_OPTION_ORGANIZATION:
      return { ...state, active: action.payload };
    case AppActionType.SET_IS_SUPER_ADMIN:
      return { ...state, isSuperAdmin: action.payload };
    case AppActionType.SET_ROUTE:
      return { ...state, route: action.payload };
    case AppActionType.REMOVE_LANGUAGE:
      return { ...state, removeLanguage: action.payload };
    case AppActionType.SET_APP_LANGUAGE:
      return { ...state, appUiLanguage: action.payload };
    default:
      return state;
  }
};
