import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class FilesService {
  constructor(private http: HttpClient) {}

  // tslint:disable-next-line:typedef
  uploadFile(file, type, relatedId = "") {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("category", type);
    if (relatedId) {
      formdata.append("relatedId", relatedId);
    }

    return this.http
      .post<any>(`${environment.apiUrl}/files`, formdata)
      .pipe(map((data) => data));
  }

  delFile(id) {
    return this.http
      .delete<any>(`${environment.apiUrl}/files/${id}`)
      .pipe(map((data) => data));
  }
}
