<div class="container">
  <div class="main-body">
    <ng-scrollbar>
      <div class="row">
        <div class="col-xs-12">
          <h1>Typography</h1>
          <hr />
          <h1>H1 96 light Axiforma</h1>
          <h2>H2 60 light Axiforma</h2>
          <h3>H3 48 regular Axiforma</h3>
          <h4>H4 34 regular Axiforma</h4>
          <h5>H5 24 regular Axiforma</h5>
          <h6>H6 20 bold Axiforma</h6>
          <p class="sub-title">Subtitle 1 16 regular Axiforma</p>
          <p class="sub-title-bold">Subtitle 2 16 bold Axiforma</p>
          <p class="body">Body 16 regular Axiforma</p>
          <p class="body-min">Body 2 14 regular Axiforma</p>
          <p class="button-font">Button 14 medium Axiforma</p>
          <p class="caption">Caption 12 regular Axiforma</p>
          <p class="overline">Overline 10 regular Axiforma</p>
          <br />
          <br />
          <h1>Colors</h1>
          <hr />
          <h6>Base Color</h6>
          <div class="d-inline-flex">
            <div class="box-white"></div>
            <div class="box-black"></div>
          </div>
          <br />
          <h6>Gray Color</h6>
          <div class="d-inline-flex">
            <div class="box-gray-m1"></div>
            <div class="box-gray"></div>
            <div class="box-gray-p1"></div>
          </div>
          <br />
          <h6>Background Color</h6>
          <div class="d-inline-flex">
            <div class="box-bg-m2"></div>
            <div class="box-bg-m1"></div>
            <div class="box-bg"></div>
            <div class="box-bg-p1"></div>
            <div class="box-bg-p2"></div>
          </div>
          <br />
          <h6>Primary Color</h6>
          <div class="d-inline-flex">
            <div class="box-primary-m1"></div>
            <div class="box-primary"></div>
            <div class="box-primary-p1"></div>
            <div class="box-primary-p2"></div>
          </div>
          <br />
          <h6>Secondary Color</h6>
          <div class="d-inline-flex">
            <div class="box-secondary-m1"></div>
            <div class="box-secondary"></div>
            <div class="box-secondary-p1"></div>
            <div class="box-secondary-p2"></div>
          </div>
          <br />
          <h6>Tertiary Color</h6>
          <div class="d-inline-flex">
            <div class="box-tertiary-m1"></div>
            <div class="box-tertiary"></div>
            <div class="box-tertiary-p1"></div>
          </div>
          <br />
          <h6>feedback Color</h6>
          <div class="d-inline-flex">
            <div class="box-feedback-error"></div>
            <div class="box-feedback-success"></div>
            <div class="box-feedback-alert"></div>
            <div class="box-feedback-info"></div>
          </div>
          <br />
          <h6>Chart Color</h6>
          <div class="d-inline-flex">
            <div class="box-chart-blue"></div>
            <div class="box-chart-gray"></div>
            <div class="box-chart-yellow"></div>
            <div class="box-chart-orange"></div>
            <div class="box-chart-green"></div>
          </div>
          <br />
          <br />
          <h1>Buttons</h1>
          <h6>Primary Button</h6>
          <button class="btn-primary">Boton de texto</button>
          <br />
          <br />
          <h6>Secondary Button</h6>
          <button class="btn-secondary">Boton de texto</button>
          <br />
          <br />
          <h6>Active Button</h6>
          <button class="btn-secondary active">Boton de texto</button>
          <br />
          <br />
          <h6>Disabled Button</h6>
          <button class="btn-disabled">Boton de texto</button>
          <br />
          <br />
          <h6>Error Button</h6>
          <button class="btn-delete">Boton de texto</button>
          <br />
          <br />
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
