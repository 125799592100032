import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "./../services/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let error;
        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          this.authService.logout();
        }
        if (
          request.url.indexOf(environment.apiUrl) >= 0 &&
          err.status === 404
        ) {
          this.router.navigate(["/dashboard/404"]);
        }

        if (err.status == 400) {
          error = err.error.message;
          const errorList = Object.keys(err.error.errors[0]).map(
            (error) => error
          );
          if (errorList.length > 0) error += `, ${errorList[0]}`;
        }

        if (err.status === 422) {
          error = err.message;
          if (
            request.url.indexOf(environment.apiUrl) >= 0 &&
            error == "validation.uuid"
          ) {
            this.router.navigate(["/dashboard/404"]);
          }
        }

        if (err.status == 500) {
          error = err.error.message;
        }

        return throwError(error);
      })
    );
  }
}
