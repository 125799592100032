import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

import { environment } from "@environments/environment";
import { User } from "@core/models/user";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class PointsService {
  selectedPoint: any;
  userData: any;
  constructor(private http: HttpClient, private authService: AuthService) {}

  // tslint:disable-next-line:typedef
  getPoints(organizationId) {
    this.userData = this.authService.userValue;

    let endPoint = organizationId
      ? `${environment.apiUrl}/accounts/${organizationId}/points`
      : `${environment.apiUrl}/points`;

    return this.http.get<User>(endPoint, {}).pipe(map((data) => data));
  }

  setSelectedPoint(point: any) {
    this.selectedPoint = point;
  }

  getSelectedPoint() {
    return this.selectedPoint;
  }

  getPointInfo(pointId) {
    return this.http
      .get<any>(`${environment.apiUrl}/points/` + pointId, {})
      .pipe(map((data) => data));
  }

  deletePoint(pointId) {
    return this.http
      .delete<any>(`${environment.apiUrl}/points/` + pointId, {})
      .pipe(map((data) => data));
  }

  addUpdatePoint(data: any) {
    if (data.id != "") {
      return this.http
        .patch<User>(`${environment.apiUrl}/points/` + data.id, data)
        .pipe(map((data) => data));
    } else {
      if (data.accountId) {
        return this.http
          .post<User>(
            `${environment.apiUrl}/accounts/${data.accountId}/points`,
            data
          )
          .pipe(map((data) => data));
      } else {
        console.error("A organization must be selected");
        return new Observable((observer) => observer.next(false));
      }
    }
  }
}
