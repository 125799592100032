<ngx-spinner
  size="medium"
  color="#00a0df"
  bdColor="#f5f6fa61"
  type="ball-scale-multiple"
></ngx-spinner>
<div class="app">
  <router-outlet></router-outlet>
</div>
<div class="message">
  <img src="assets/logos/arvision.png" />
  <h4>{{ "general.noMobileVersion" | translate }}</h4>
</div>
